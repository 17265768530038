import './App.css';

function App() {
    return (
            <div className="App">
                <div className={'holder'}>
                    <div className={'logo-title'}>
                        <span className={'logo-title-end'}>watt</span>.mobi
                    </div>
                    <div className={'subtitle'}>
                        the virtual power plant
                    </div>

                    <div className={'incentive-message'}>
                        Energy decentralization is happening now!
                    </div>
                </div>
            </div>
    );
}

export default App;
