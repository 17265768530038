import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDCKC0oN92D0hsEwkD3U13LmPMvokqdN00",
    authDomain: "watt-mobi.firebaseapp.com",
    projectId: "watt-mobi",
    storageBucket: "watt-mobi.appspot.com",
    messagingSenderId: "283695092979",
    appId: "1:283695092979:web:aa9bc044747efd945a9386",
    measurementId: "G-EMKGEL1BF6"
};
initializeApp(firebaseConfig);
ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
);

reportWebVitals();
